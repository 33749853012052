/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//Fonts
import "./src/styles/fonts.css"

// Reset CSS and Semantic UI.
//import "./src/styles/normalize.css"
//import "./src/styles/semantic.css"
import "./src/styles/normalize_compressed.css"
import "./src/styles/semantic_compressed.css"
//import "semantic-ui-css/semantic.min.css"
import "./src/styles/semantic-override.less"
